import type { AppProps } from 'next/app'
import '@dialectlabs/react-ui/index.css'

import { App as BaseApp } from '@components/App'
import { App as HubApp } from '@hub/App'

import '../styles/index.css'
import '../styles/typography.css'
import '@hub/components/controls/RichTextEditor/index.css'
import '../components/DropdownMenu/index.css'
import '@multifarm/solana-realms/dist/multifarm-solana-realms.css'
import queryClient from '@hooks/queries/queryClient'
import { QueryClientProvider } from '@tanstack/react-query'

import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: '0cb14049-b0b5-4f29-b40d-734140cfb52a',
  clientToken: 'pubbf9ba9297b39130fc29e1fe43c1b9935',
  site: 'datadoghq.com',
  service: 'dao.metaplex.com',
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

datadogRum.startSessionReplayRecording()

export default function App({ Component, pageProps, router }: AppProps) {
  // **NOTE**
  // Do not perform any data fetches or insert/attaach any providers in this
  // component. This component is for routing between the sub-apps ONLY. Add
  // the providers and perform data fetches in the relevant sub-apps (`HubApp`,
  // `BaseApp`) instead.
  if (router.pathname.startsWith('/code')) {
    return <Component {...pageProps} />
  }

  if (
    router.pathname.startsWith('/verify-wallet') ||
    router.pathname.startsWith('/matchday/verify-wallet') ||
    router.pathname.startsWith('/realm/[id]/governance') ||
    router.pathname.startsWith('/realm/[id]/config')
  ) {
    return (
      <HubApp minimal>
        <Component {...pageProps} />
      </HubApp>
    )
  }

  if (
    router.pathname.startsWith('/realm/[id]') ||
    router.pathname.startsWith('/ecosystem') ||
    router.pathname.startsWith('/discover') ||
    router.pathname.startsWith('/feed') ||
    router.pathname.startsWith('/stats')
  ) {
    return (
      <HubApp>
        <Component {...pageProps} />
      </HubApp>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      {/*       <ReactQueryDevtools initialIsOpen={false} />
       */}
      <BaseApp>
        <Component {...pageProps} />
      </BaseApp>
    </QueryClientProvider>
  )
}
