import { Program, Provider, web3 } from '@coral-xyz/anchor'
// import { IDL, VoterStakeRegistry } from './voter_stake_registry'
import { IDL, MplStaking } from './voter_stake_registry_with_rewards'

export const DEFAULT_VSR_ID = new web3.PublicKey(
  // 'vsr2nfGVNHmSY8uxoBGqq8AQbwz3JwaEaHqGbsTPXqQ'
  '9XZ7Ku7FYGVk3veKba6BRKTFXoYJyh4b4ZHC6MfaTUE8' // new version, with rewards
)
export const DEFAULT_REWARDS_ID = new web3.PublicKey(
  'BF5PatmRTQDgEKoXR7iHRbkibEEi83nVM38cUKWzQcTR'
)
export const REWARD_POOL_SPACE = 64480

export class VsrClient {
  constructor(
    // public program: Program<VoterStakeRegistry>,
    public program: Program<MplStaking>,
    public devnet?: boolean
  ) {}

  static async connect(
    provider: Provider,
    programId: web3.PublicKey = DEFAULT_VSR_ID,
    devnet?: boolean
  ): Promise<VsrClient> {
    const idl = IDL

    return new VsrClient(
      // new Program<VoterStakeRegistry>(
      new Program<MplStaking>(
        // idl as VoterStakeRegistry,
        idl as MplStaking,
        programId,
        provider
        // ) as Program<VoterStakeRegistry>,
      ) as Program<MplStaking>,
      devnet
    )
  }
}
