import { BN } from '@coral-xyz/anchor'
import { MintInfo } from '@solana/spl-token'
import { Keypair, PublicKey, SystemProgram } from '@solana/web3.js'
import { TokenProgramAccount } from '@utils/tokens'
import { DEFAULT_REWARDS_ID, REWARD_POOL_SPACE } from './client'
import { ConnectionContext } from '@utils/connection'

export interface Voter {
  deposits: Deposit[]
  voterAuthority: PublicKey
  registrar: PublicKey
  //there are more fields but no use for them on ui yet
}

interface VotingMint {
  baselineVoteWeightScaledFactor: BN
  digitShift: number
  grantAuthority: PublicKey
  lockupSaturationSecs: BN
  maxExtraLockupVoteWeightScaledFactor: BN
  mint: PublicKey
}

// export type LockupType = 'none' | 'monthly' | 'cliff' | 'constant' | 'daily'
export type LockupType = keyof LockupKind
export type LockupPeriod = keyof LockupPeriodKind
export interface Registrar {
  governanceProgramId: PublicKey
  realm: PublicKey
  realmAuthority: PublicKey
  realmGoverningTokenMint: PublicKey
  votingMints: VotingMint[]
  rewardPool: PublicKey
  //there are more fields but no use for them on ui yet
}
interface LockupKind {
  none: object
  // daily: object
  // monthly: object
  // cliff: object
  constant: object
}

interface LockupPeriodKind {
  none: object
  threeMonths: object
  sixMonths: object
  oneYear: object
  flex: object
  test: object
}

interface Lockup {
  kind: LockupKind
  startTs: BN
  endTs: BN
  cooldownEndsAt: BN
  cooldownRequested: boolean
  period: LockupPeriodKind
}

export interface Deposit {
  // allowClawback: boolean
  amountDepositedNative: BN
  // amountInitiallyLockedNative: BN
  isUsed: boolean
  lockup: Lockup
  votingMintConfigIdx: number
}
export interface DepositWithMintAccount extends Deposit {
  mint: TokenProgramAccount<MintInfo>
  index: number
  available: BN
  vestingRate: BN | null
  currentlyLocked: BN
  nextVestingTimestamp: BN | null
  votingPower: BN
  votingPowerBaseline: BN
}

export const emptyPk = '11111111111111111111111111111111'

export const getRegistrarPDA = async (
  realmPk: PublicKey,
  mint: PublicKey,
  clientProgramId: PublicKey
) => {
  const [registrar, registrarBump] = await PublicKey.findProgramAddress(
    [realmPk.toBuffer(), Buffer.from('registrar'), mint.toBuffer()],
    clientProgramId
  )
  return {
    registrar,
    registrarBump,
  }
}

export const getVoterPDA = async (
  registrar: PublicKey,
  walletPk: PublicKey,
  clientProgramId: PublicKey
) => {
  const [voter, voterBump] = await PublicKey.findProgramAddress(
    [registrar.toBuffer(), Buffer.from('voter'), walletPk.toBuffer()],
    clientProgramId
  )

  return {
    voter,
    voterBump,
  }
}

export const getVoterWeightPDA = async (
  registrar: PublicKey,
  walletPk: PublicKey,
  clientProgramId: PublicKey
) => {
  const [voterWeightPk, voterWeightBump] = await PublicKey.findProgramAddress(
    [
      registrar.toBuffer(),
      Buffer.from('voter-weight-record'),
      walletPk.toBuffer(),
    ],
    clientProgramId
  )

  return {
    voterWeightPk,
    voterWeightBump,
  }
}

export const getRewardVaultPDA = (
  rewardPool: PublicKey,
  rewardMint: PublicKey,
  rewardsProgramId: PublicKey
) => {
  const [rewardVault, rewardVaultBump] = PublicKey.findProgramAddressSync(
    [Buffer.from('vault'), rewardPool.toBuffer(), rewardMint.toBuffer()],
    rewardsProgramId
  )

  return {
    rewardVault,
    rewardVaultBump,
  }
}

export const getDepositMiningPDA = (
  voterAuthority: PublicKey,
  rewardPool: PublicKey,
  rewardsProgramId: PublicKey
) => {
  const [depositMining, depositMiningBump] = PublicKey.findProgramAddressSync(
    [Buffer.from('mining'), voterAuthority.toBuffer(), rewardPool.toBuffer()],
    rewardsProgramId
  )

  return {
    depositMining,
    depositMiningBump,
  }
}

export const createRewardPoolAccount = async (
  connection: ConnectionContext,
  walletPk: PublicKey
) => {
  const rewardPoolKeypair = Keypair.generate()
  const rewardPoolPk = rewardPoolKeypair.publicKey
  const rewardPoolRent = await connection.current.getMinimumBalanceForRentExemption(
    REWARD_POOL_SPACE
  )

  const createRewardPoolIx = SystemProgram.createAccount({
    fromPubkey: walletPk,
    newAccountPubkey: rewardPoolPk,
    lamports: rewardPoolRent,
    space: REWARD_POOL_SPACE,
    programId: DEFAULT_REWARDS_ID,
  })

  return { createRewardPoolIx, rewardPoolPk, rewardPoolKeypair }
}
